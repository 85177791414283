import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    NewVersionPopupContainer as SourceNewVersionPopupContainer
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import { isCrawler, isSSR } from 'Util/Browser';

import { SUBSEQUENT_PAGE_LOAD_FLAG } from './NewVersionPopup.config';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace ZnetPwa/Component/NewVersionPopup/Container/NewVersionPopupContainer */
export class NewVersionPopupContainer extends SourceNewVersionPopupContainer {
    componentDidMount() {
        const { showPopup, goToPreviousHeaderState, device } = this.props;

        // Create a BroadcastChannel to listen for messages from the service worker
        const channel = new BroadcastChannel('sw-messages');

        channel.addEventListener('message', (event) => {
            if (event.data && event.data.type === 'RELOAD_PAGE') {
                window.location.reload();
            }
        });

        // Determine if this is the first visit to not show new version popup
        const isFirstVisit = !BrowserDatabase.getItem(SUBSEQUENT_PAGE_LOAD_FLAG);

        if (isFirstVisit) {
            BrowserDatabase.setItem('true', SUBSEQUENT_PAGE_LOAD_FLAG);
        }

        if (!('serviceWorker' in navigator) || isCrawler() || isSSR()) {
            return;
        }

        // Listening for service worker updates
        navigator.serviceWorker.ready.then(
            /** @namespace ZnetPwa/Component/NewVersionPopup/Container/then */
            (registration) => {
                registration.addEventListener('updatefound', () => {
                    const newWorker = registration.installing;

                    newWorker.addEventListener('statechange', () => {
                        if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                            if (!isFirstVisit) {
                                showPopup({
                                    title: __('New version available!')
                                });

                                if (device.isMobile) {
                                    goToPreviousHeaderState();
                                }
                            }
                        }
                    });
                });

                // Listener for when a new service worker takes control
                navigator.serviceWorker.addEventListener('controllerchange', () => {
                    if (!isFirstVisit) {
                        showPopup({
                            title: __('New version available!')
                        });
                    }
                });
            }
        ).catch(
            /** @namespace ZnetPwa/Component/NewVersionPopup/Container/then/catch */
            (error) => console.error('Service Worker registration error:', error)
        );
    }

    toggleNewVersion() {
        const channel = new BroadcastChannel('sw-messages');

        navigator.serviceWorker.ready.then(
            /** @namespace ZnetPwa/Component/NewVersionPopup/Container/then */
            (registration) => {
                if (registration.waiting) {
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                    registration.waiting.addEventListener('statechange', (event) => {
                        if (event.target.state === 'activated') {
                            channel.postMessage({ type: 'RELOAD_PAGE' });
                        }
                    });
                } else {
                    channel.postMessage({ type: 'RELOAD_PAGE' });
                }
            }
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer);
