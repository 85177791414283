/* eslint-disable max-lines */
import { CONFIGURABLE } from '@scandipwa/scandipwa/src/util/Product';
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { connect } from 'react-redux';

import { ADVANCED_QUOTE_FORM } from 'Component/AdvancedQuoteForm/AdvancedQuoteForm.config';
import { CALCULATOR_ID } from 'Component/Calculator/Calculator.config';
import { PRICE_FORM, QUOTE_FORM } from 'Component/DripForms/DripForms.config';
import { CMS_PAGE } from 'Component/Header/Header.config';
import { TERMS_POPUP } from 'Component/TermsPopup/TermsPopup.config';
import CmsPageQuery from 'Query/CmsPage.query';
import { LOADING_TIME } from 'Route/CmsPage/CmsPage.config';
import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourcemapStateToProps
} from 'SourceRoute/CmsPage/CmsPage.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { customerType } from 'Type/Account';
import { CategoryTreeType } from 'Type/Category';
import CSS from 'Util/CSS';
import { debounce } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import CmsPage from './CmsPage.component';
import {
    CERAMIC_TILE_IDENTIFIER,
    FIVE_PERCENT_DECIMAL,
    FORTY_NINE,
    KARNDEAN,
    MOHAWK,
    MSI,
    SAMPLE_CMS,
    SHAW,
    THREE,
    THREE_HUNDRED_FORTY_NINE,
    TWO_HUNDRED_FIFTY,
    TWO_HUNDRED_NINETY_NINE,
    TWO_HUNDRED_SEVENTY_NINE,
    TWO_THOUSAND_MILLISECONDS
} from './CmsPage.config';

export const PrerenderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Prerender/Prerender.dispatcher'
);

/** @namespace ZnetPwa/Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: () => dispatch(showPopup(TERMS_POPUP)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updatePrerender: (status) => PrerenderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updatePrerender(status, dispatch)
    )
});

/** @namespace ZnetPwa/Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourcemapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    popupPayload: state.PopupReducer.popupPayload,
    dripLoaded: state.DripReducer.dripLoaded,
    urlRewriteType: state.UrlRewritesReducer.urlRewrite.type
});

/** @namespace ZnetPwa/Route/CmsPage/Container/CmsPageContainer */
export class CmsPageContainer extends SourceCmsPageContainer {
    static propTypes = {
        ...this.propTypes,
        customer: customerType.isRequired,
        isBlock: PropTypes.bool,
        category: CategoryTreeType,
        showPopup: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        popupPayload: PropTypes.object,
        hideActiveOverlay: PropTypes.func.isRequired,
        is_terms_popup: PropTypes.bool,
        is_price_form: PropTypes.bool,
        pageIdentifier: PropTypes.string,
        showNotification: PropTypes.func.isRequired,
        formId: PropTypes.string,
        addToCartRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        dripLoaded: PropTypes.bool.isRequired,
        urlRewriteType: PropTypes.string.isRequired,
        updatePrerender: PropTypes.func.isRequired,
        homePageHeightRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
    };

    static defaultProps = {
        ...this.defaultProps,
        addToCartRef: () => {},
        isBlock: false,
        popupPayload: {},
        is_price_form: false,
        is_terms_popup: false,
        pageIdentifier: '',
        category: {},
        formId: '',
        homePageHeightRef: () => {}
    };

    containerFunctions = {
        ...this.containerFunctions,
        closeQuotePopup: this.closeQuotePopup.bind(this),
        setQuantity: this.setQuantity.bind(this)
    };

    // Used for Drip forms
    timer = null;

    cmsPageHeightRef = createRef();

    state = {
        ...this.state,
        currentDripFormId: 0,
        areTermsOpened: false,
        isFormSubmitting: false,
        isFormEmbedded: false,
        token: false,
        totalArea: 0,
        isCustomerAddressAvailable: false,
        moveAttemptCounter: 0
    };

    __construct(props) {
        super.__construct(props);

        const {
            is_terms_popup,
            popupPayload = {},
            formId
        } = props;

        this.state = {
            ...this.state,
            totalArea: popupPayload[formId]?.totalArea || 0
        };

        if (is_terms_popup) {
            this.updateBreadcrumbs({ title: 'Sample Sets Form' });
        } else {
            this.updateBreadcrumbs();
        }
    }

    containerProps = () => {
        const {
            popupPayload,
            formId
        } = this.props;
        const {
            isFormEmbedded,
            isFormSubmitting,
            totalArea
        } = this.state;

        if (formId === QUOTE_FORM || formId === ADVANCED_QUOTE_FORM || formId === PRICE_FORM) {
            const {
                productType,
                qtyInputRef,
                quantity,
                imageUrl,
                collection_color,
                collection_name,
                brandPlankWidth,
                coverage_area_type,
                minQuantity,
                maxQuantity
            } = popupPayload[formId];

            return {
                productType,
                formId,
                isFormSubmitting,
                isFormEmbedded,
                qtyInputRef,
                quantity,
                imageUrl,
                collection_color,
                collection_name,
                brandPlankWidth,
                coverage_area_type,
                minQuantity,
                maxQuantity,
                totalArea,
                cmsPageHeightRef: this.cmsPageHeightRef
            };
        }

        return {
            formId,
            isFormSubmitting,
            isFormEmbedded,
            cmsPageHeightRef: this.cmsPageHeightRef
        };
    };

    componentDidMount() {
        const {
            isOffline,
            isOnlyPlaceholder,
            isBlock,
            customer: {
                addresses = [],
                default_billing,
                default_shipping
            } = {}
        } = this.props;

        window.scrollTo(0, 0);

        const { isLoading } = this.state;

        if (addresses.length) {
            if (default_billing || default_shipping) {
                this.setState({ isCustomerAddressAvailable: true });
            }
        }

        if (isOffline && isLoading) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (!isOnlyPlaceholder && !isBlock) {
            this.requestPage();
        }

        if (!isOnlyPlaceholder && isBlock) {
            this.requestBlock();
        }
    }

    componentWillUnmount() {
        const { is_terms_popup } = this.props;
        const dripScript = document.getElementById('dripScript');

        if (dripScript && !is_terms_popup) {
            this.dripFormCleanup();
        }

        clearTimeout(this.timer);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            homePageHeightRef,
            location: { pathname } = {},
            pageIdentifiers,
            pageIds,
            isBlock,
            dripLoaded,
            customer: {
                addresses = [],
                default_billing,
                default_shipping
            } = {},
            updatePrerender,
            urlRewriteType
        } = this.props;

        const {
            location: { pathname: prevPathname } = {},
            pageIdentifiers: prevPageIdentifiers,
            pageIds: prevPageIds
        } = prevProps;
        const { isCustomerAddressAvailable, prevIsLoading } = prevState;

        const { isLoading, isFormEmbedded } = this.state;

        if (isCustomerAddressAvailable && !addresses.length && !!default_billing && !!default_shipping) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isCustomerAddressAvailable: false });
        } else if (addresses.length) {
            if (default_billing || default_shipping) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ isCustomerAddressAvailable: true });
            }
        }

        if (
            !isBlock
            && (pathname !== prevPathname
            || pageIds !== prevPageIds
            || pageIdentifiers !== prevPageIdentifiers)
        ) {
            this.requestPage();
        }

        if (isBlock) {
            this.requestBlock();
        }

        if (!isLoading && !isFormEmbedded && dripLoaded) {
            this.insertDripFormScript();
        }

        if (prevIsLoading !== isLoading) {
            updatePrerender({ [`pageRender${ urlRewriteType }`]: true });

            if (homePageHeightRef?.current) {
                // Used to improve CLS
                CSS.setVariable(
                    homePageHeightRef,
                    'homepage-height',
                    `${ this.cmsPageHeightRef?.current.offsetHeight }px`
                );
            }
        }
    }

    setQuantity(value) {
        const {
            popupPayload,
            formId
        } = this.props;
        const {
            setQuantity,
            areaPerBox
        } = popupPayload[formId];
        const { currentDripFormId } = this.state;
        const formDOMNode = document.getElementById(`dfwid-${ currentDripFormId }`);
        const coverage = (value * areaPerBox).toFixed(2);

        this.setState({ totalArea: coverage });
        this.setFieldValues(value, formDOMNode, true);
        setQuantity(value);
    }

    dripFormCleanup() {
        const {
            currentDripFormId
        } = this.state;

        const form = document.getElementById(`dfwid-${ currentDripFormId }`);

        if (form) {
            form.style.display = 'none';

            document.body.insertBefore(form, document.body.lastChild);
        }
    }

    insertDripFormScript() {
        const {
            isFormEmbedded
        } = this.state;
        const form = document.querySelector('#drip-form');

        if (form && !isFormEmbedded) {
            this.moveDripForm(form);
        }
    }

    moveDripForm(form) {
        const { isFormEmbedded } = this.state;

        if (form) {
            const { formId } = form.dataset;

            if (formId) {
                const formArray = window?._dcfg?.forms;

                const currentForm = formArray?.filter((dripForm) => dripForm.public_id === parseInt(formId, 10));
                const currentFormId = currentForm?.[0]?.id;

                if (currentFormId) {
                    this.setState({ currentDripFormId: currentFormId });

                    if (!isFormEmbedded) {
                        if (!this.executeMove(currentFormId, formId)) {
                            this.executeMoveWrapper(currentFormId, formId);
                        }
                    }
                }
            }
        }
    }

    // As Drip loads script for each for that cannot be tracked
    // timer is needed to increase chance of scripts being loaded
    // TODO: Needs refactoring for more reliable solution
    executeMoveWrapper(currentFormId, formId) {
        const { moveAttemptCounter, isFormEmbedded } = this.state;

        window.clearTimeout(this.timer);
        this.timer = window.setTimeout(() => {
            if (moveAttemptCounter < THREE && !isFormEmbedded) {
                this.setState({ moveAttemptCounter: moveAttemptCounter + 1 }, () => {
                    if (!this.executeMove(currentFormId, formId)) {
                        this.executeMoveWrapper(currentFormId, formId);
                    }
                });
            }
        }, TWO_THOUSAND_MILLISECONDS);
    }

    openTermsPopup(e) {
        const { showPopup } = this.props;
        e?.preventDefault();

        showPopup();
    }

    createTermsConditionsLink() {
        const conditionsWrapper = document.createElement('div');
        const conditionsButton = document.createElement('button');
        const conditionsText = document.createElement('span');

        conditionsText.innerHTML = 'I agree to&nbsp';
        conditionsButton.innerHTML = 'terms & conditions/privacy policy';
        conditionsButton.style.textDecoration = 'underline';
        conditionsButton.onclick = (e) => this.openTermsPopup(e);
        conditionsWrapper.appendChild(conditionsText);
        conditionsWrapper.appendChild(conditionsButton);

        return conditionsWrapper;
    }

    executeMove(currentFormId, formId) {
        const {
            hideActiveOverlay,
            formId: formName
        } = this.props;
        const formDOMNode = document.getElementById(`dfwid-${ currentFormId }`);

        if (formDOMNode) {
            // Style form as it is impossible to override through CSS
            // eslint-disable-next-line no-param-reassign
            formDOMNode.style.backgroundColor = 'transparent';
            // eslint-disable-next-line no-param-reassign
            formDOMNode.style.position = 'relative';
            // eslint-disable-next-line no-param-reassign
            formDOMNode.style.width = '100%';
            // eslint-disable-next-line no-param-reassign
            formDOMNode.style.height = '100%';
            // eslint-disable-next-line no-param-reassign
            formDOMNode.style.display = 'block';
            // eslint-disable-next-line no-param-reassign
            formDOMNode.style.zIndex = 10;
            // eslint-disable-next-line no-param-reassign
            formDOMNode.style.padding = 0;
            formDOMNode.classList.add('dripForm');

            formDOMNode?.querySelector('.dfwid-close')?.remove();

            if (formName === PRICE_FORM || formName === QUOTE_FORM || formName === ADVANCED_QUOTE_FORM) {
                const submitBtn = formDOMNode?.getElementsByClassName('dfwid-submit-button');

                this.setFieldValues(0, formDOMNode);

                if (submitBtn) {
                    if (!document.getElementsByClassName(`${ formName }-Cancel`).length) {
                        const cancelBtn = document.createElement('button');
                        cancelBtn.innerHTML = 'CANCEL';
                        cancelBtn.className = `${ formName }-Cancel`;
                        cancelBtn.style.cursor = 'pointer';
                        cancelBtn.style.margin = 'auto';
                        cancelBtn.style.fontSize = '1.4rem';
                        cancelBtn.addEventListener('click', (event) => {
                            event.preventDefault();
                            hideActiveOverlay();
                        });

                        submitBtn[0].parentNode.insertBefore(cancelBtn, submitBtn[0]);
                    }
                }
            }

            if (document.getElementById(SAMPLE_CMS)) {
                const termsAndConditionsInput = formDOMNode?.querySelector("input[name='fields[terms]'");

                if (termsAndConditionsInput) {
                    termsAndConditionsInput.nextElementSibling.innerHTML = '';
                    termsAndConditionsInput.nextElementSibling.appendChild(this.createTermsConditionsLink());
                }
            }

            const formWrapper = formDOMNode.querySelector('#dfwid-buffer');
            formWrapper.style.maxWidth = '100%';

            const formShadow = formWrapper.querySelector('.dfwid-popup');
            formShadow.style.boxShadow = 'none';
            formShadow.style.background = 'transparent';

            // Handle form submit
            // Hide thank you message
            const form = document.getElementById(`dfwid-form-${ currentFormId }`);
            form.onsubmit = () => this.clearForm(form);

            // Append the form to the CMS page
            document.getElementById('drip-form').appendChild(formDOMNode);

            // eslint-disable-next-line no-undef
            _dcq.push(['showForm', { id: formId }]);

            this.setState({ isFormEmbedded: true });

            return true;
        }

        return false;
    }

    _createAddressObject(address) {
        const {
            telephone = '',
            firstname = '',
            middlename = '',
            lastname = '',
            street = [],
            city = '',
            postcode = '',
            region: {
                region: state = ''
            } = {}

        } = address;
        // Filters for null undefined etc.
        const name = [firstname, middlename, lastname].filter(Boolean).join(' ');
        // Causes linter error if using template
        // eslint-disable-next-line prefer-template
        const streetAddress = street?.reduce((prevVal, curVal) => [prevVal, curVal].filter(Boolean).join(', '), '');

        return {
            name,
            streetAddress,
            telephone,
            city,
            postcode,
            state
        };
    }

    _getCustomerAddress() {
        const {
            customer: {
                email,
                addresses = [],
                default_billing,
                default_shipping
            } = {}
        } = this.props;

        if (addresses.length) {
            if (default_billing || default_shipping) {
                const customerAddress = addresses.find((address) => {
                    const addressIdAsString = (address.id).toString();
                    return addressIdAsString === default_billing || addressIdAsString === default_shipping;
                });

                if (Object.keys(customerAddress)?.length > 0) {
                    return {
                        email,
                        ...this._createAddressObject(customerAddress)
                    };
                }

                return {
                    email,
                    ...this._createAddressObject(addresses[0])
                };
            }

            return {
                email,
                ...this._createAddressObject(addresses[0])
            };
        }

        return {};
    }

    // Used to set values on pop-up open and to change them on qty change
    setFieldValues(value = 0, formDOMNode, isQtyChange = false) {
        const {
            popupPayload,
            formId
        } = this.props;
        const {
            isCustomerAddressAvailable
        } = this.state;
        const {
            productType,
            productName,
            quantity: productQuantity,
            areaPerBox,
            totalArea,
            productUrl,
            price,
            sfPrice,
            boxPrice,
            manufacturer,
            sku
        } = popupPayload[formId];
        const currentQty = value || productQuantity;
        const areaCoveredByBoxes = (areaPerBox * currentQty).toFixed(2);
        const boxTotalPrice = (price * currentQty).toFixed(2);
        const subtotal = (price * currentQty).toFixed(2);
        const customerAddress = this._getCustomerAddress();
        let shippingToHomeFee = TWO_HUNDRED_FIFTY;

        // Customer information fields for autofill
        if (!isQtyChange) {
            const name = formDOMNode?.querySelector("input[name='fields[name]'");
            if (name) {
                name.value = isCustomerAddressAvailable ? customerAddress.name : '';
            }

            const email = formDOMNode?.querySelector("input[name='fields[email]'");
            if (email) {
                email.value = isCustomerAddressAvailable ? customerAddress.email : '';
            }

            const phone = formDOMNode?.querySelector("input[name='fields[phone]'");
            if (phone) {
                phone.value = isCustomerAddressAvailable ? customerAddress.telephone : '';
            }

            const street = formDOMNode?.querySelector("input[name='fields[street_address]'");
            if (street) {
                street.value = isCustomerAddressAvailable ? customerAddress.streetAddress : '';
            }

            const city = formDOMNode?.querySelector("input[name='fields[city]'");
            if (city) {
                city.value = isCustomerAddressAvailable ? customerAddress.city : '';
            }

            const state = formDOMNode?.querySelector("select[name='fields[state_province]'");
            if (state) {
                state.value = isCustomerAddressAvailable ? customerAddress.state : '';
            }

            const zip = formDOMNode?.querySelector("input[name='fields[zip]'");
            if (zip) {
                zip.value = isCustomerAddressAvailable ? customerAddress.postcode : '';
            }

            // Checking preffered payment method as credit card
            // eslint-disable-next-line max-len
            const payment = formDOMNode?.querySelector("input[name='fields[preferred_payment_method_if_i_were_to_make_a_transaction]'");
            if (payment) {
                payment.checked = true;
            }

            const product = formDOMNode?.querySelector("input[name='fields[product]'");
            const coverageInCartons = formDOMNode?.querySelector("input[name='fields[coverage_units_in_carton]'");
            const productUrlInput = formDOMNode?.querySelector("input[name='fields[product_url]'");
            const sfPriceInput = formDOMNode?.querySelector("input[name='fields[sf_price]'");
            const boxPriceInput = formDOMNode?.querySelector("input[name='fields[box_price]'");
            const skuInput = formDOMNode?.querySelector("input[name='fields[sku]'");
            const shipToFee = formDOMNode?.querySelector("input[name='fields[shipping_fee_ship_to_pricing]'");

            // Hidden and not editable fields
            if (product) {
                product.parentElement.style.display = 'none';
                product.value = productName;
            }

            if (coverageInCartons) {
                coverageInCartons.parentElement.style.display = 'none';

                if (productType !== CONFIGURABLE) {
                    coverageInCartons.value = areaPerBox;
                }
            }

            if (productUrlInput) {
                productUrlInput.parentElement.style.display = 'none';
                productUrlInput.value = productUrl;
            }

            if (sfPriceInput) {
                sfPriceInput.parentElement.style.display = 'none';

                if (productType !== CONFIGURABLE) {
                    sfPriceInput.value = sfPrice;
                }
            }

            if (boxPriceInput) {
                boxPriceInput.parentElement.style.display = 'none';
                boxPriceInput.value = boxPrice;
            }

            if (skuInput) {
                skuInput.parentElement.style.display = 'none';
                skuInput.value = sku;
            }

            if (shipToFee) {
                switch (manufacturer) {
                case SHAW:
                    shippingToHomeFee = THREE_HUNDRED_FORTY_NINE;
                    break;
                case MSI:
                    shippingToHomeFee = THREE_HUNDRED_FORTY_NINE;
                    break;
                case MOHAWK:
                    shippingToHomeFee = TWO_HUNDRED_SEVENTY_NINE;
                    break;
                case KARNDEAN:
                    shippingToHomeFee = THREE_HUNDRED_FORTY_NINE;
                    break;
                default:
                    shippingToHomeFee = TWO_HUNDRED_FIFTY;
                }

                shipToFee.value = `$${ new Intl.NumberFormat().format(shippingToHomeFee) }`;
                shipToFee.parentElement.style.display = 'none';
            }
        }

        const shipToTotal = formDOMNode?.querySelector("input[name='fields[total_ship_to_pricing]'");
        const warehouseSubtotal = formDOMNode?.querySelector("input[name='fields[subtotal_warehouse]'");
        const warehouseDiscount = formDOMNode?.querySelector("input[name='fields[discount_warehouse]'");
        const warehouseFee = formDOMNode?.querySelector("input[name='fields[pickup_fee_warehouse]'");
        const warehouseTotal = formDOMNode?.querySelector("input[name='fields[total_warehouse]'");
        const shipToSubtotal = formDOMNode?.querySelector("input[name='fields[subtotal_ship_to_pricing]'");
        const boxTotalPriceInput = formDOMNode?.querySelector("input[name='fields[box_total_price]'");
        const totalSqRequested = formDOMNode?.querySelector("input[name='fields[total_sqft_requested]'");
        const quantity = formDOMNode?.querySelector("input[name='fields[quantity_boxes]'");
        const productQuantityAndText = productType !== CONFIGURABLE
        // eslint-disable-next-line max-len
            ? `${ currentQty } (${ currentQty } carton(s) at ${ areaPerBox } sf/carton = ${ areaCoveredByBoxes } sf)`
            : currentQty;

        const discount = (subtotal * FIVE_PERCENT_DECIMAL).toFixed(2);
        let pickupFee = FORTY_NINE;

        if (totalSqRequested) {
            totalSqRequested.parentElement.style.display = 'none';

            if (productType !== CONFIGURABLE) {
                totalSqRequested.value = new Intl.NumberFormat().format(totalArea);
            }
        }

        if (quantity) {
            quantity.parentElement.style.display = 'none';
            quantity.value = productQuantityAndText;

            // Styling to display input and btn side by side
            const parentDiv = quantity.parentNode;

            if (parentDiv) {
                parentDiv.style.height = '100px';
            }
        }

        if (boxTotalPriceInput) {
            boxTotalPriceInput.parentElement.style.display = 'none';
            boxTotalPriceInput.value = `$${ new Intl.NumberFormat().format(boxTotalPrice) }`;
        }

        if (shipToSubtotal) {
            shipToSubtotal.parentElement.style.display = 'none';
            shipToSubtotal.value = `$${ new Intl.NumberFormat().format(subtotal) }`;
        }

        if (shipToTotal) {
            // With (+...) converting to number
            const shipToTotals = (+subtotal) + shippingToHomeFee;
            shipToTotal.value = `$${ new Intl.NumberFormat().format(shipToTotals) }`;
            shipToTotal.parentElement.style.display = 'none';
        }

        if (warehouseSubtotal) {
            warehouseSubtotal.parentElement.style.display = 'none';
            warehouseSubtotal.value = `$${ new Intl.NumberFormat().format(subtotal) }`;
        }

        if (warehouseDiscount) {
            warehouseDiscount.parentElement.style.display = 'none';
            warehouseDiscount.value = `$${ new Intl.NumberFormat().format(discount) }`;
        }

        if (warehouseFee) {
            if (manufacturer === SHAW) {
                pickupFee = TWO_HUNDRED_NINETY_NINE;
            }
            warehouseFee.value = `$${ pickupFee }`;
            warehouseFee.parentElement.style.display = 'none';
        }

        if (warehouseTotal) {
            const warehouseTotals = subtotal - discount + pickupFee;
            warehouseTotal.value = `$${ new Intl.NumberFormat().format(warehouseTotals) }`;
            warehouseTotal.parentElement.style.display = 'none';
        }
    }

    clearForm(form) {
        const { showNotification, hideActiveOverlay, addToCartRef } = this.props;
        this.setState({ isFormSubmitting: true });

        setTimeout(() => {
            const errorWrappers = form.getElementsByClassName('drip-errors');
            const errors = Array.prototype.filter.call(errorWrappers, (elem) => elem.innerHTML?.length > 0);

            if (!errors.length) {
                form.reset();
                this.setState({ isFormSubmitting: false });
                hideActiveOverlay();
                // Trigger add to cart to show popup
                addToCartRef.current?.firstChild.click();
                showNotification('success', __('Your quote request was submitted.'));
            } else {
                showNotification('error', __('Please fill all required fields!'));
                this.setState({ isFormSubmitting: false });
            }
        }, TWO_THOUSAND_MILLISECONDS);
    }

    closeQuotePopup(e) {
        const {
            popupPayload,
            formId,
            hideActiveOverlay
        } = this.props;
        const { qtyInputRef } = popupPayload[formId];

        e.preventDefault();
        hideActiveOverlay();
        qtyInputRef.current.focus();
        qtyInputRef.current.select();
        location.href = `#${ CALCULATOR_ID }`;
    }

    requestBlock() {
        const { category: { cms_block: cmsPage } } = this.props;

        if (cmsPage) {
            this.onPageLoad({ cmsPage });
        }
    }

    requestPage() {
        const {
            pageIdentifier
        } = this.props;

        if (pageIdentifier !== '') {
            this.setState({ isLoading: true });

            this.fetchData(
                [CmsPageQuery.getQuery({ identifier: pageIdentifier })],
                this.onPageLoad,
                () => this.setState({ isLoading: false })
            );

            return;
        }

        const params = this.getRequestQueryParams();
        const { id, identifier } = params;

        if (!id && !identifier) {
            return;
        }

        this.setState({ isLoading: true });

        this.fetchData(
            [CmsPageQuery.getQuery(params)],
            this.onPageLoad,
            () => this.setState({ isLoading: false })
        );
    }

    onPageLoad = ({ cmsPage: page }) => {
        const {
            location: { pathname } = {},
            updateMeta,
            setHeaderState,
            updateBreadcrumbs,
            category,
            isBlock,
            is_terms_popup
        } = this.props;

        const updatedPageObj = page;

        if (isBlock) {
            const {
                content: {
                    title = ''
                } = {},
                meta_title = '',
                meta_keywords = '',
                meta_description = ''
            } = category;
            const { identifier = '' } = page;

            if (identifier === CERAMIC_TILE_IDENTIFIER) {
                updatedPageObj.page_width = 'full';
            }

            updatedPageObj.content_heading = title;
            updatedPageObj.meta_title = meta_title;
            updatedPageObj.meta_keywords = meta_keywords;
            updatedPageObj.meta_description = meta_description;
        }

        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords
        } = updatedPageObj;

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        if (is_terms_popup) {
            updateBreadcrumbs({ title: 'Sample Sets Form' });
        } else {
            updateBreadcrumbs(updatedPageObj);
        }

        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.origin + window.location.pathname,
            robots: 'follow, index'
        });

        if (
            pathname !== appendWithStoreCode('/')
            && pathname !== '/'
        ) {
            setHeaderState({
                name: CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack()
            });
        }
        this.setState({ page: updatedPageObj, isLoading: false, isPageLoaded: true });
    };

    render() {
        return (
            <CmsPage
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
